<template>
  <!-- 模拟考试练习 页面模版 -->
  <div class="course">
    <header-t/>
    <nav2/>
    <div class="cont">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item :to="{path: '/classroom?parent=1&second=11'}">八大员</el-breadcrumb-item>
        <el-breadcrumb-item>监理安全新取证</el-breadcrumb-item>
        <el-breadcrumb-item>三类人员建筑（B类）2022年模拟考试练习</el-breadcrumb-item>
      </el-breadcrumb>
      <el-row class="class-item">
        <el-col :span="24">
          <div class="es-section course-detail-section course-detail-section--normal  clearfix">
            <div class="course-detail-top clearfix">
              <span class="tags mrm"></span>
              <span class="course-detail-heading">三类人员建筑（B类）2022年模拟考试练习</span>
              <ul class="course-operation  course-operation--normal   hidden-xs hidden-sm clearfix">
                <li class="es-share top js-es-share">
                  <a href="#" class="btn btn-default btn-xs dropdown-toggle" title="分享" :aria-expanded="false">
                    <i class="es-icon es-icon-share"></i>
                  </a>
                </li>
                <li>
                  <a href="" class="btn btn-default btn-xs">
                    <i class="es-icon es-icon-exit"></i>
                  </a>
                </li>
            </ul>
            </div>
            <section class="course-freelearn-section">
              <div class="row">
                <div class="col-md-3">
                  <div class="course-progress">
                    <div class="cricle-progress" id="freeprogress">
                        <span class="percent">学习进度
                          <br>
                        <span class="num">0%</span>
                      </span>
                        <canvas height="181" width="181" style="height: 146px; width: 146px;"></canvas>
                    </div>
                  </div>
                </div>
                <div class="col-md-7 hidden-xs hidden-sm">
                  <ul class="clearfix text-12 course-learn-list ">
                    <li>
                      <p class="mb15 color-gray">
                        <i class="es-icon es-icon-iccheckcircleblack24px mrm"></i>已完成
                      </p>
                      <span class="text-16">
                        <span class="color-primary">0</span> /
                        <span class="mrl">10</span>任务
                      </span>
                    </li>
                    <li>
                      <p class="mb15 color-gray">
                        <i class="es-icon es-icon-today mrm"></i>学习有效期
                      </p>
                      <span class="text-16">2024-12-21</span>
                    </li>
                    <li>
                      <p class="mb15 color-gray">
                        <i class="es-icon es-icon-book mrm"></i>下一学习任务
                      </p>
                      <a href="#" class="link-dark text-16">模拟试卷一</a>
                    </li>
                  </ul>
                </div>
                <div class="col-md-2 hidden-xs hidden-sm pt10">
                  <a href="#" class="btn btn-primary btn-lg ">开始学习</a>
                </div>
              </div>
            </section>
          </div>
        </el-col>
      </el-row>
    </div>
    <!-- 目录模块 -->
    <div class="cont mar-bottom">
      <el-row :gutter="20">
        <el-col :span="18">
          <el-card shadow="nerve" class="card">
            <el-tabs v-model="activeName" @tab-click="handleClick">
            <el-tab-pane label="目录" name="first">
              <ul class="task-list task-list-md task-list-hover infinite-container">
                <li class="task-item task-content mouse-control infinite-item color-gray bg-gray-lighter">
                  <a href="#" style="float:left;margin-left: 20px; text-decoration: none; color: #616161;">课时 1 : 模拟试卷一</a>
                  <span class="right-menu color-gray " style="float:right;margin-right: 20px; color: #0000008f;">01:30:00</span>
                </li>
              </ul>
              <ul class="task-list task-list-md task-list-hover infinite-container">
                <li class="task-item task-content mouse-control infinite-item color-gray bg-gray-lighter">
                  <a href="#" style="float:left;margin-left: 20px; text-decoration: none; color: #616161;">课时 1 : 模拟试卷一</a>
                  <span class="right-menu color-gray " style="float:right;margin-right: 20px; color: #0000008f;">01:30:00</span>
                </li>
              </ul>
              <ul class="task-list task-list-md task-list-hover infinite-container">
                <li class="task-item task-content mouse-control infinite-item color-gray bg-gray-lighter">
                  <a href="#" style="float:left;margin-left: 20px; text-decoration: none; color: #616161;">课时 1 : 模拟试卷一</a>
                  <span class="right-menu color-gray " style="float:right;margin-right: 20px; color: #0000008f;">01:30:00</span>
                </li>
              </ul>
              <ul class="task-list task-list-md task-list-hover infinite-container">
                <li class="task-item task-content mouse-control infinite-item color-gray bg-gray-lighter">
                  <a href="#" style="float:left;margin-left: 20px; text-decoration: none; color: #616161;">课时 1 : 模拟试卷一</a>
                  <span class="right-menu color-gray " style="float:right;margin-right: 20px; color: #0000008f;">01:30:00</span>
                </li>
              </ul>
              <ul class="task-list task-list-md task-list-hover infinite-container">
                <li class="task-item task-content mouse-control infinite-item color-gray bg-gray-lighter">
                  <a href="#" style="float:left;margin-left: 20px; text-decoration: none; color: #616161;">课时 1 : 模拟试卷一</a>
                  <span class="right-menu color-gray " style="float:right;margin-right: 20px; color: #0000008f;">01:30:00</span>
                </li>
              </ul>
              <ul class="task-list task-list-md task-list-hover infinite-container">
                <li class="task-item task-content mouse-control infinite-item color-gray bg-gray-lighter">
                  <a href="#" style="float:left;margin-left: 20px; text-decoration: none; color: #616161;">课时 1 : 模拟试卷一</a>
                  <span class="right-menu color-gray " style="float:right;margin-right: 20px; color: #0000008f;">01:30:00</span>
                </li>
              </ul>
              <ul class="task-list task-list-md task-list-hover infinite-container">
                <li class="task-item task-content mouse-control infinite-item color-gray bg-gray-lighter">
                  <a href="#" style="float:left;margin-left: 20px; text-decoration: none; color: #616161;">课时 1 : 模拟试卷一</a>
                  <span class="right-menu color-gray " style="float:right;margin-right: 20px; color: #0000008f;">01:30:00</span>
                </li>
              </ul>
              <ul class="task-list task-list-md task-list-hover infinite-container">
                <li class="task-item task-content mouse-control infinite-item color-gray bg-gray-lighter">
                  <a href="#" style="float:left;margin-left: 20px; text-decoration: none; color: #616161;">课时 1 : 模拟试卷一</a>
                  <span class="right-menu color-gray " style="float:right;margin-right: 20px; color: #0000008f;">01:30:00</span>
                </li>
              </ul>
              <ul class="task-list task-list-md task-list-hover infinite-container">
                <li class="task-item task-content mouse-control infinite-item color-gray bg-gray-lighter">
                  <a href="#" style="float:left;margin-left: 20px; text-decoration: none; color: #616161;">课时 1 : 模拟试卷一</a>
                  <span class="right-menu color-gray " style="float:right;margin-right: 20px; color: #0000008f;">01:30:00</span>
                </li>
              </ul>
              <ul class="task-list task-list-md task-list-hover infinite-container">
                <li class="task-item task-content mouse-control infinite-item color-gray bg-gray-lighter">
                  <a href="#" style="float:left;margin-left: 20px; text-decoration: none; color: #616161;">课时 1 : 模拟试卷一</a>
                  <span class="right-menu color-gray " style="float:right;margin-right: 20px; color: #0000008f;">01:30:00</span>
                </li>
              </ul>
            </el-tab-pane>
            <el-tab-pane label="资料区" name="second">资料区</el-tab-pane>
            <el-tab-pane label="介绍" name="third">介绍</el-tab-pane>
          </el-tabs>
          </el-card>
        </el-col>
        <el-col :span="6">
          <el-card shadow="nerve" class="card">
            <div class="panel">
              <div class="tit">
                <h3>授课教师</h3>
              </div>
              <div class="body">
                <div class="media-left">
                  <img class="avatar-md" src="	https://www.wlmqcol.com/files/user/2020/04-18/1123208982b0740286.jpg" />
                </div>
                <div class="media-right">
                  <div class="title">
                      网校管理员
                  </div>
                  <div class="content">班级课程管理员</div>
                </div>
              </div>
            </div>
          </el-card>
          <!-- 最新学员 -->
          <el-card shadow="nerve" class="card mar20">
            <div class="panel">
              <div class="tit">
                <h3>最新学员</h3>
              </div>
              <div class="body">
                <div class="media-member">
                  <img class="avatar-md" src="	https://www.wlmqcol.com/files/user/2020/04-18/1123208982b0740286.jpg" />
                  <img class="avatar-md" src="	https://www.wlmqcol.com/files/user/2020/04-18/1123208982b0740286.jpg" />
                  <img class="avatar-md" src="	https://www.wlmqcol.com/files/user/2020/04-18/1123208982b0740286.jpg" />
                  <img class="avatar-md" src="	https://www.wlmqcol.com/files/user/2020/04-18/1123208982b0740286.jpg" />
                  <img class="avatar-md" src="	https://www.wlmqcol.com/files/user/2020/04-18/1123208982b0740286.jpg" />
                  <img class="avatar-md" src="	https://www.wlmqcol.com/files/user/2020/04-18/1123208982b0740286.jpg" />
                  <img class="avatar-md" src="	https://www.wlmqcol.com/files/user/2020/04-18/1123208982b0740286.jpg" />
                </div>
              </div>
            </div>
          </el-card>
          <!-- 动态学员 -->
          <el-card shadow="nerve" class="card mar20">
            <div class="panel">
              <div class="tit">
                <h3>动态学员</h3>
              </div>
              <div class="body">
                <ul class="dynamic">
                  <li>王若城 开始学习 模拟试卷九</li>
                  <li>cl13201312131 完成了 模拟试312131 完成了 模拟卷八</li>
                  <li>yanan 开始学习 模拟试卷四</li>
                  <li>yanan 开始学习 模拟试卷四</li>
                  <li>yanan 开始学习 模拟试卷四</li>
                </ul>
              </div>
            </div>
          </el-card>
        </el-col>
      </el-row>
    </div>
    <Footer/>
  </div>
</template>

<script>
import HeaderT from '@/components/HeaderT'
import Nav2 from '@/components/Nav2'
import Footer from '@/components/Footer'
export default {
  name: 'CourseDetail',
  components:{
    HeaderT,
    Nav2,
    Footer
  },
  data() {
    return {
      activeName: 'first'
    }
  },
  methods: {
    handleClick(tab, event) {
      console.log(tab, event);
    }
  }
}
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
.course{
  height: 100%;
  background-color: #f5f5f5;
  .cont{
    margin: 16px auto 0;
    width: 1170px;
    .class-item{
      background-color: #fff;
      padding: 10px;
      margin-top: 20px;
      .title{
        text-align: left;
        font-weight: bold;
      }
    }
  }
  .mar-bottom{
    margin-bottom: 20px;
  }
  .panel{
    .tit{
      text-align: left;
      line-height: 30px;
      border-bottom: 1px solid #f5f5f5;
    }
    .body{
      padding: 15px 0;
      .media-left{
        display: table-cell;
        vertical-align: top;
        padding-right: 15px;
        .avatar-md{
          width: 60px;
          height: 60px;
          border-radius:50%;
          margin: 5px;
        }
      }
      .media-member{
        display: table-cell;
        vertical-align: top;
        .avatar-md{
            float: left;
            width: 36px;
            height: 36px;
            border-radius:50%;
            margin: 5px;
        }
      }
      .dynamic{
        margin-top: 5px;
        li{
          padding-bottom: 15px;
          text-align: left;
          font-size: 14px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          word-wrap: normal;
          color: #666;

        }
      }
      .media-right{
        display: table-cell;
        vertical-align: top;
        font-size: 14px;
        .title{
          margin: 10px 0 10px 0;
          text-align: left;
          
        }
        .content{
          color: #919191;
        }
      }
    }
  }
  .mar20{
    margin-top: 20px;
  }
  .course-detail-section--normal {
    padding: 15px!important;
}
.course-detail-section {
    position: relative;
    ul {
    list-style: none;

    .course-operation--normal {
      right: 10px;
      top: 20px;
    }
    .course-operation {
      position: absolute;
      list-style-type: none;
      padding-left: 0;
      li {
        float: left;
        margin-right: 10px;
        .course-detail-section .course-operation li>.btn {
          font-size: 14px;
          color: #919191;
          .btn-default:hover{
            background-color: #f5f5f5;
            border-color: #dcdcdc;
          }
          .btn-xs {
            padding: 1px 5px;
            line-height: 1.5;
            border-radius: 2px;
          }
          .btn {
              display: inline-block;
              margin-bottom: 0;
              font-weight: 400;
              text-align: center;
              vertical-align: middle;
              touch-action: manipulation;
              cursor: pointer;
              background-image: none;
              border: 1px solid transparent;
              white-space: nowrap;
              user-select: none;
          }
}
    }
    .es-share {
    position: relative;
    display: inline-block;
}
  }
}
    .course-detail-top {
    padding-right: 130px;
    .course-detail-heading {
    vertical-align: top;
    display: flex;
    color: #313131;
    font-size: 24px;
    word-wrap: break-word;
    word-break: break-all;
}
      .tags{
        float: left;
      }
      .mrm {
    margin-right: 10px!important;
    }
  }
}
.es-section {
    background: #fff;
    margin-bottom: 20px;
    border-radius: 4px;
}
section{
  display: block;
}
.course-freelearn-section {
    background-color: #fafafa;
    margin: 60px -15px 20px;
    padding: 35px 15px;
    height: 70px;
    .course-learn-list {
    list-style: none;
    font-size: 12px!important
}
    .course-learn-list>li:first-child {
        width: 33.33%;
    }
    .course-learn-list>li{
        float: left;
        width: 33.33%;
        .mb15 {
          margin-bottom: 15px!important;
      }
      .color-gray {
          color: #919191!important;
      }
      p {
          margin: 0 0 10px;
          .mrm {
            margin-right: 10px!important;
        }
        .es-icon {
            line-height: 1;
            font-family: es-icon!important;
            font-size: 16px;
            font-style: normal;
        }
      }
      .text-16 {
            font-size: 16px!important;
      .color-primary {
            color: #43bc60!important;
        }
        .mrl {
            margin-right: 20px!important;
        }
      }
      .link-dark {
            cursor: pointer;
            color: #616161!important;
      }
      a {
            text-decoration: none;
        }
    }
    .course-progress {
    top: -50px;
    left: 75px;
    position: absolute;
    width: 160px;
    height: 160px;
    border-radius: 50%;
    background-color: #fff;
    box-shadow: 0 0 18px 2px;
    .cricle-progress {
      position: absolute;
      left: 7.5px;
      top: 7.5px;
      width: 145px;
      height: 145px;
      .percent {
          position: absolute;
          top: 40px;
          left: 0;
          width: 100%;
          text-align: center;
          font-size: 12px;
          font-weight: 700;
          color: #919191;
         .num {
          display: inline-block;
          margin-top: 10px;
          font-size: 30px;
          line-height: 1;
          font-weight: 700;
          color: #43bc60;
        }
      }
    }
  }
  
    .row {
    margin-left: -10px;
    margin-right: -10px;
    .col-md-3 {
      float: left;
      width: 25%;
      position: relative;
      min-height: 1px;
      padding-left: 12px;
      padding-right: 12px;  
      }
      .col-md-7 {
        float: left;
        width: 50%;
        position: relative;
        min-height: 1px;
        padding-left: 12px;
        padding-right: 12px;  
      }
      .col-md-2 {
        float: left;
        width: 18%;
        position: relative;
        min-height: 1px;
        padding-left: 12px;
        padding-right: 12px;
        .btn.btn-lg {
          font-size: 14px;
          padding: 12px 35px;
          line-height: 1.3333333;
          border-radius: 4px;
        }
        .btn-primary {
          border-color: #36964d;
          background-color: #43bc60;
          color: #fff;
        }
        a{
          text-decoration: none;
        }  
      }
      .pt10 {
          padding-top: 20px!important;
      }
    }
  }
  .task-list {
    list-style: none;
    padding: 0;
    margin-bottom: 0;
    background-color: #f5f5f5!important;
    height: 52px;
    line-height: 52px;
    margin-top: 10px;
    .task-list-md{
      .task-item{
        margin-bottom: 10px;
        position: relative;
      }
    }
  }
}
</style>